export enum TileShortcutApps{
    Projects = "Ishtar.Projects",
    List = "Ishtar.CRM",
    DMS = "Ishtar.DMS",
    Tasks = "Ishtar.Tasks"
}

export enum TileShortcutActions{
    FilterTemplate = "Filter on form templates",
    OpenTemplate = "Open form template",
    TBD = "T.B.D",
    FilterDocument = "Filter on documents"
}