import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatChipEditedEvent,
  MatChipInputEvent,
  MatChipsModule,
} from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  NgxMaterialTimepickerModule,
  NgxMaterialTimepickerTheme,
} from 'ngx-material-timepicker';
import {
  ConfirmDialogData,
  ConfirmPopupComponent,
  ContextMenuAction,
  Day,
  GroupUser,
  LoaderService,
} from 'processdelight-angular-components';
import {
  Subject,
  debounceTime,
  filter,
  forkJoin,
  map,
  of,
  takeUntil,
  tap,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ContactDetailParam } from 'src/app/core/domain/models/contactDetailParam';
import { GraphGroup } from 'src/app/core/domain/models/graph-group.model';
import {
  PermanenceAppConfig,
  TreeDepartmentConfig,
} from 'src/app/core/domain/models/permanence-app-config';
import { Ishtar365ActionsService } from 'src/app/core/services/ishtar365-actions.service';
import { Ishtar365Service } from 'src/app/core/services/ishtar365.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { v4 } from 'uuid';
import { GroupPickerComponent } from './group-picker/group-picker.component';
import { ElementRefDirective } from './group-tree/element-ref.component';
import { GroupTreeComponent } from './group-tree/group-tree.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  selector: 'app-permanence-sync',
  templateUrl: './permanence-sync.component.html',
  styleUrls: ['./permanence-sync.component.scss'],
  imports: [
    MatRadioModule,
    MatCardModule,
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatChipsModule,
    NgxMaterialTimepickerModule,
    MatAutocompleteModule,
    GroupPickerComponent,
    MatRippleModule,
    MatTooltipModule,
    GroupTreeComponent,
    ElementRefDirective,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
})
export class PermanenceSyncComponent implements OnInit, OnDestroy {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  destroy$ = new Subject<void>();
  isSaving$ = new Subject<boolean>();
  config: PermanenceAppConfig | undefined;
  interval = 'weekly';
  extraDepConfig = false;
  useDelimiter = false;
  mappingForm!: FormGroup;
  departmentForm!: FormGroup;
  groupForm!: FormGroup;
  roleForm!: FormGroup;
  intervalForm!: FormGroup;
  adminForm!: FormGroup;
  departmentUserMappingForm!: FormGroup;
  wildCardForm!: FormGroup;
  users: GroupUser[] = [];
  foundGroups: GraphGroup[] = [];
  childGroups: GraphGroup[] = [];
  selectedGroup: GraphGroup | undefined;
  days: Day[] = [];
  contactDetailParams: ContactDetailParam[] = [];

  groupSyncDepartmentDefinitions = new FormArray<FormGroup>([]);
  selectedGroupSyncDep: FormGroup | undefined;
  departmentPeople?: GroupUser[] = [];

  @ViewChild('pane') treePane?: ElementRef;
  cardMovedSubject = new Subject<string>();

  contactDetailFields: {
    id: number;
    internalName: string;
    displayName: string;
    required: boolean;
    type: string;
    typeofsync: string[];
  }[] = [
    {
      id: 1,
      internalName: 'Department',
      displayName: 'Department',
      required: true,
      type: 'string',
      typeofsync: ['users'],
    },
    {
      id: 2,
      internalName: 'JobTitle',
      displayName: 'Job title',
      required: false,
      type: 'string',
      typeofsync: ['users', 'groups'],
    },
    {
      id: 3,
      internalName: 'MobileNumber',
      displayName: 'Mobile number',
      required: false,
      type: 'string',
      typeofsync: ['users', 'groups'],
    },
    {
      id: 4,
      internalName: 'TelephoneNumber',
      displayName: 'Phone number',
      required: false,
      type: 'string',
      typeofsync: ['users', 'groups'],
    },
  ];
  roleFields: string[] = [];
  azureFields: {
    id: number;
    internalName: string;
    displayName: string;
    customSecurityAttribute: boolean;
    extensionAttribute: boolean;
  }[] = [
    {
      id: 1,
      internalName: 'Department',
      displayName: 'Department',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 2,
      internalName: 'DisplayName',
      displayName: 'Display name',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 3,
      internalName: 'GivenName',
      displayName: 'Given name',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 4,
      internalName: 'JobTitle',
      displayName: 'Job title',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 5,
      internalName: 'Mail',
      displayName: 'Mail',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 6,
      internalName: 'MobilePhone',
      displayName: 'Mobile phone',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 7,
      internalName: 'Surname',
      displayName: 'Surname',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 8,
      internalName: 'UserPrincipalName',
      displayName: 'User principal name',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 9,
      internalName: 'BusinessPhones',
      displayName: 'Business phones',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 10,
      internalName: 'City',
      displayName: 'City',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 11,
      internalName: 'CompanyName',
      displayName: 'Company name',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 12,
      internalName: 'Country',
      displayName: 'Country',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 14,
      internalName: 'OfficeLocation',
      displayName: 'Office location',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 15,
      internalName: 'PostalCode',
      displayName: 'Postal code',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 16,
      internalName: 'PreferredLanguage',
      displayName: 'Preferred language',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 17,
      internalName: 'StreetAddress',
      displayName: 'Street address',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
    {
      id: 18,
      internalName: 'FaxNumber',
      displayName: 'Fax',
      customSecurityAttribute: false,
      extensionAttribute: false,
    },
  ];

  get fieldMappings() {
    return this.mappingForm.controls['mappingRows'] as FormArray;
  }
  get roleMappings() {
    return this.roleForm.controls['roleRows'] as FormArray;
  }
  get departmentMappings() {
    return this.departmentForm.controls['departmentRows'] as FormArray;
  }
  get parentGroupFormControl() {
    return this.groupForm.get('parentGroup') as FormControl;
  }
  get parentGroupFormControlVal() {
    return this.foundGroups?.find(
      (f) => f.id == this.parentGroupFormControl?.value
    )?.displayName;
  }
  get departmentMappingFormGroup() {
    return this.departmentUserMappingForm.get('departmentMapping') as FormGroup;
  }
  get departmentNumberMappingFormGroup() {
    return this.departmentUserMappingForm.get(
      'departmentNumberMapping'
    ) as FormGroup;
  }
  get departmentNameMappingFormGroup() {
    return this.departmentUserMappingForm.get(
      'departmentNameMapping'
    ) as FormGroup;
  }

  parentGroupControl = new FormControl('');
  adminGroupsFilterControl = new FormControl('');
  adminGroupsControl = new FormControl<{ id: string; name: string }[]>([]);
  isLoadingadminGroups = false;
  adminGroups: GraphGroup[] = [];
  typeOfSyncControl = new FormControl<'users' | 'groups'>('users');
  delimiterFormControl = new FormControl('', [
    Validators.maxLength(1),
    Validators.minLength(1),
    this.useDelimiter ? Validators.required : Validators.nullValidator,
  ]);
  navColor = '';
  navContrast = '';
  procDelTheme: NgxMaterialTimepickerTheme = {
    container: {
      buttonColor: this.navColor,
    },
    dial: {
      dialBackgroundColor: this.navColor,
    },
    clockFace: {
      clockHandColor: this.navColor,
    },
  };
  constructor(
    private appSubscriptionFacade: AppSubscriptionFacade,
    private readonly ishtar365Actions: Ishtar365ActionsService,
    private readonly translations: TranslationService,
    private readonly ishtar365: Ishtar365Service,
    private organizationFacade: OrganizationFacade,
    private loaderService: LoaderService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  get hasChanges() {
    return (
      this.mappingForm.dirty ||
      this.roleForm.dirty ||
      this.departmentForm.dirty ||
      this.groupForm.dirty ||
      this.delimiterFormControl.dirty ||
      this.adminForm.dirty ||
      this.departmentUserMappingForm.dirty ||
      this.wildCardForm.dirty ||
      this.groupSyncDepartmentDefinitions.dirty
    );
  }

  get hasErrors() {
    return !(
      this.mappingForm.valid &&
      this.roleForm.valid &&
      this.groupForm.valid &&
      (this.delimiterFormControl.valid || this.delimiterFormControl.disabled) &&
      this.intervalForm.valid
    );
  }

  ngOnInit(): void {
    for (let index = 0; index < 15; index++) {
      const element = {
        id: this.azureFields.length + 2,
        internalName: `ExtensionAttribute${index + 1}`,
        displayName: `Extension attribute ${index + 1}`,
        customSecurityAttribute: false,
        extensionAttribute: true,
      };
      this.azureFields.push(element);
    }
    this.organizationFacade.tempProperties$
      .pipe(map((p) => p.navColor))
      .subscribe((color) => {
        this.navColor = color ?? '#124464';
        document.documentElement.style.setProperty(
          '--nav-color',
          this.navColor
        );
        this.procDelTheme.clockFace!.clockHandColor = this.navColor;
        this.procDelTheme.dial!.dialBackgroundColor = this.navColor;
        this.procDelTheme.container!.buttonColor = this.navColor;
      });
    this.organizationFacade.tempProperties$
      .pipe(map((p) => p.navContrast))
      .subscribe((contrast) => {
        this.navContrast = contrast ?? 'fff';
        document.documentElement.style.setProperty(
          '--nav-contrast',
          this.navContrast
        );
      });
    this.adminGroupsFilterControl.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$),
        filter((t) => !!t && t.trim() !== ''),
        tap(() => (this.isLoadingadminGroups = true)),
        switchMap((v) => this.ishtar365.getGraphGroups(v!))
      )
      .subscribe((groups) => {
        this.adminGroups = groups;
        this.isLoadingadminGroups = false;
      });
    this.mappingForm = new FormGroup({
      mappingRows: new FormArray([]),
    });
    this.roleForm = new FormGroup({
      basedOnField: new FormControl(3),
      roleRows: new FormArray([]),
      isCustomSecurityAttribute: new FormControl(false),
      isExtensionAttribute: new FormControl(false),
    });
    this.departmentForm = new FormGroup({
      departmentRows: new FormArray([]),
    });
    this.departmentForm.disable();
    this.intervalForm = new FormGroup({
      interval: new FormControl('none'),
      startTime: new FormControl('00:00'),
      day: new FormControl(''),
    });
    this.adminForm = new FormGroup({
      basedOnField: new FormControl(''),
      nameValue: new FormControl(''),
      isCustomSecurityAttribute: new FormControl(false),
      isExtensionAttribute: new FormControl(false),
    });
    this.wildCardForm = new FormGroup({
      basedOnField: new FormControl(''),
      nameValue: new FormControl(''),
      isCustomSecurityAttribute: new FormControl(false),
      isExtensionAttribute: new FormControl(false),
    });
    this.departmentUserMappingForm = new FormGroup({
      departmentMapping: new FormGroup({
        basedOnField: new FormControl(''),
        nameValue: new FormControl(''),
        isCustomSecurityAttribute: new FormControl(false),
        isExtensionAttribute: new FormControl(false),
      }),
      departmentNumberMapping: new FormGroup({
        basedOnField: new FormControl(''),
        isCustomSecurityAttribute: new FormControl(false),
        isExtensionAttribute: new FormControl(false),
      }),
      departmentNameMapping: new FormGroup({
        basedOnField: new FormControl(''),
        isCustomSecurityAttribute: new FormControl(false),
        isExtensionAttribute: new FormControl(false),
      }),
    });

    this.groupForm = new FormGroup({});
    this.ishtar365
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => (this.users = users));

    this.typeOfSyncControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const departmentField = this.contactDetailFields[0];
        const deparmtentForm = (
          this.mappingForm.controls.mappingRows as FormArray
        ).controls
          .find((c) => c.value.contactFieldId === departmentField.id)
          ?.get('azureFieldId');
        switch (value) {
          case 'users':
            deparmtentForm?.setValidators(Validators.required);
            deparmtentForm?.enable();
            this.toggleUseDelimter(true);
            this.delimiterFormControl.enable();
            break;
          case 'groups':
            deparmtentForm?.clearValidators();
            deparmtentForm?.disable();
            deparmtentForm?.setValue(null);
            this.toggleUseDelimter(false);
            this.delimiterFormControl.disable();
            if (this.groupSyncDepartmentDefinitions.controls.length === 0)
              PermanenceSyncComponent.addNewDepConfig(
                this.groupSyncDepartmentDefinitions
              );
            break;
        }

        this.mappingForm.updateValueAndValidity();
      });

    this.loaderService.startLoading(
      this.translations.getTranslation('loadingConfiguration'),
      () =>
        forkJoin([
          this.ishtar365.getContactDetailParams(),
          this.appSubscriptionFacade.getAADSyncConfig$(
            'Ishtar.Permanence',
            PermanenceAppConfig
          ),
          this.ishtar365.getCustomSecurityAttributes(),
          this.ishtar365.getDays(),
        ]).pipe(
          map(([params, config, customSecurityAttributes, days]) => {
            this.days = [...days].filter(
              (d) => d.name != 'Calendar day' && d.name != 'Working day'
            );
            this.config = config;
            if (params) {
              [...params].map((p) => {
                this.contactDetailFields.push({
                  id: this.contactDetailFields.length + 1,
                  internalName: p.name!,
                  displayName: p.name!,
                  required: false,
                  type: 'string',
                  typeofsync: ['users', 'groups'],
                });
              });
            }
            if (customSecurityAttributes) {
              [...customSecurityAttributes].map((c) => {
                this.azureFields.push({
                  id: this.azureFields.length + 2,
                  internalName: c.name!,
                  displayName: c.name!,
                  customSecurityAttribute: true,
                  extensionAttribute: false,
                });
              });
            }
            this.mappingForm = new FormGroup({
              mappingRows: new FormArray(
                this.contactDetailFields.map(
                  (c) =>
                    new FormGroup({
                      contactFieldId: new FormControl(c.id),
                      azureFieldId: new FormControl(
                        this.config?.userParameters?.length
                          ? this.azureFields.find(
                              (a) =>
                                a.internalName ==
                                this.config?.userParameters?.find(
                                  (c2) => c2.ishtarValue == c.internalName
                                )?.aADValue
                            )?.id
                          : undefined,
                        c.internalName == 'Department' ||
                        c.internalName == 'JobTitle'
                          ? Validators.required
                          : undefined
                      ),
                    })
                )
              ),
            });
            if (
              this.config?.departmentDelimiter != '' &&
              this.config?.departmentDelimiter != null
            ) {
              this.useDelimiter = true;
              this.delimiterFormControl.setValue(
                this.config?.departmentDelimiter
              );
            }
            if (this.config?.typeOfSync) {
              this.typeOfSyncControl.setValue(this.config?.typeOfSync);
            }

            this.roleForm = new FormGroup({
              basedOnField: new FormControl(
                this.config?.roleMapping
                  ? this.azureFields.find(
                      (a) =>
                        a.internalName == this.config?.roleMapping?.basedOnField
                    )?.id
                  : 3,
                Validators.required
              ),
              isCustomSecurityAttribute: new FormControl(
                this.config?.roleMapping?.isCustomSecurityAttribute ?? false
              ),
              isExtensionAttribute: new FormControl(
                this.config?.roleMapping?.isExtensionAttribute ?? false
              ),
              roleRows: new FormArray(
                this.config?.roleMapping?.roles?.length
                  ? this.config?.roleMapping.roles.map(
                      (r) =>
                        new FormGroup({
                          roleValue: new FormControl(
                            r.role,
                            Validators.required
                          ),
                          isSupervisor: new FormControl(
                            r.isSupervisor ?? false
                          ),
                        })
                    )
                  : []
              ),
            });
            if (this.config?.adminConfig) {
              this.adminForm = new FormGroup({
                basedOnField: new FormControl(
                  this.config?.adminConfig?.basedOnField
                    ? this.azureFields.find(
                        (a) =>
                          a.internalName ==
                          this.config?.adminConfig?.basedOnField
                      )?.id
                    : ''
                ),
                nameValue: new FormControl(
                  this.config?.adminConfig?.nameValue
                    ? this.config?.adminConfig?.nameValue
                    : ''
                ),
                isCustomSecurityAttribute: new FormControl(
                  this.config?.adminConfig?.isCustomSecurityAttribute
                ),
                isExtensionAttribute: new FormControl(
                  this.config?.roleMapping?.isExtensionAttribute ?? false
                ),
              });
            }
            if (this.config?.departmentWildCard) {
              this.wildCardForm = new FormGroup({
                basedOnField: new FormControl(
                  this.config?.departmentWildCard?.basedOnField
                    ? this.azureFields.find(
                        (a) =>
                          a.internalName ==
                          this.config?.departmentWildCard?.basedOnField
                      )?.id
                    : ''
                ),
                nameValue: new FormControl(
                  this.config?.departmentWildCard?.nameValue
                    ? this.config?.departmentWildCard?.nameValue
                    : ''
                ),
                isCustomSecurityAttribute: new FormControl(
                  this.config?.departmentWildCard?.isCustomSecurityAttribute
                ),
                isExtensionAttribute: new FormControl(
                  this.config?.departmentWildCard?.isExtensionAttribute ?? false
                ),
              });
            }
            if (this.config?.departmentUserConfig) {
              this.departmentUserMappingForm = new FormGroup({
                departmentMapping: new FormGroup({
                  basedOnField: new FormControl(
                    this.config?.departmentUserConfig?.departmentMapping
                      ?.basedOnField
                      ? this.azureFields.find(
                          (a) =>
                            a.internalName ==
                            this.config?.departmentUserConfig?.departmentMapping
                              ?.basedOnField
                        )?.id
                      : ''
                  ),
                  nameValue: new FormControl(
                    this.config?.departmentUserConfig?.departmentMapping?.nameValue
                  ),
                  isCustomSecurityAttribute: new FormControl(
                    this.config?.departmentUserConfig?.departmentMapping?.isCustomSecurityAttribute
                  ),
                  isExtensionAttribute: new FormControl(
                    this.config?.roleMapping?.isExtensionAttribute ?? false
                  ),
                }),
                departmentNumberMapping: new FormGroup({
                  basedOnField: new FormControl(
                    this.config?.departmentUserConfig?.departmentNumberMapping
                      ?.basedOnField
                      ? this.azureFields.find(
                          (a) =>
                            a.internalName ==
                            this.config?.departmentUserConfig
                              ?.departmentNumberMapping?.basedOnField
                        )?.id
                      : ''
                  ),
                  isCustomSecurityAttribute: new FormControl(
                    this.config?.departmentUserConfig?.departmentNumberMapping?.isCustomSecurityAttribute
                  ),
                  isExtensionAttribute: new FormControl(
                    this.config?.roleMapping?.isExtensionAttribute ?? false
                  ),
                }),
                departmentNameMapping: new FormGroup({
                  basedOnField: new FormControl(
                    this.config?.departmentUserConfig?.departmentNameMapping
                      ?.basedOnField
                      ? this.azureFields.find(
                          (a) =>
                            a.internalName ==
                            this.config?.departmentUserConfig
                              ?.departmentNameMapping?.basedOnField
                        )?.id
                      : ''
                  ),
                  isCustomSecurityAttribute: new FormControl(
                    this.config?.departmentUserConfig?.departmentNameMapping?.isCustomSecurityAttribute
                  ),
                  isExtensionAttribute: new FormControl(
                    this.config?.roleMapping?.isExtensionAttribute ?? false
                  ),
                }),
              });
            }
            if (this.config?.groupParameters) {
              this.groupForm = new FormGroup({
                parentGroup: new FormControl(
                  this.config?.groupParameters.parentGroup?.id
                ),
              });
              this.selectedGroup = this.config?.groupParameters.parentGroup;
            } else {
              this.groupForm = new FormGroup({
                parentGroup: new FormControl(null),
                users: new FormControl(null),
                supervisors: new FormControl(null),
              });
            }

            this.adminGroupsControl.setValue(
              this.config?.groupsAdminADGroups ?? []
            );

            this.intervalForm = new FormGroup({
              interval: new FormControl(
                this.config?.intervalParameters?.interval ?? 'none'
              ),
              startTime: new FormControl(
                this.config?.intervalParameters?.startTime ?? '00:00'
              ),
              day: new FormControl(
                this.config?.intervalParameters?.day
                  ? this.days[this.config?.intervalParameters?.day]
                  : this.days[0].id
              ),
            });
            this.config?.departmentParameters?.length
              ? (this.extraDepConfig = true)
              : (this.extraDepConfig = false);

            if (this.config?.typeOfSync === 'groups') {
              const addNewDepConfig = (c: TreeDepartmentConfig): FormGroup => {
                return new FormGroup({
                  id: new FormControl(c.id),
                  departmentName: new FormControl(
                    c.departmentName,
                    Validators.required
                  ),
                  supervisor: new FormControl(c?.supervisor ?? ''),
                  groupIds: new FormControl(c?.groupIds ?? [], [
                    Validators.required,
                    Validators.minLength(1),
                  ]),
                  children: new FormArray(
                    c.children?.map((child) => addNewDepConfig(child)) ?? []
                  ),
                });
              };
              if (
                this.config.groupsDepartmentConfig?.departments?.length ||
                0 > 0
              ) {
                this.groupSyncDepartmentDefinitions.clear();
                const d = this.config.groupsDepartmentConfig!.departments[0];
                this.groupSyncDepartmentDefinitions.push(addNewDepConfig(d));
              } else {
                PermanenceSyncComponent.addNewDepConfig(
                  this.groupSyncDepartmentDefinitions
                );
              }
            }
          })
        )
    );
    this.parentGroupControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        tap((t) => (!t ? (this.foundGroups = []) : undefined)),
        filter((t) => !!t),
        switchMap((t) => this.ishtar365.getGraphGroups(t!))
      )
      .subscribe((groups) => (this.foundGroups = groups));
    this.setupActionBar();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }
  onIntervalChange(event: any) {
    this.interval = event.value;
  }

  onSupervisorChange(index: number) {
    this.roleMappings.controls
      .filter((c, index2) => index2 != index)
      .forEach((c) => {
        c.get('isSupervisor')?.setValue(false);
      });
  }

  onDropdownClick(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    }, 0);
  }
  onParentGroupChange(event: any) {
    this.selectedGroup = this.foundGroups.find((f) => f.id == event.value);
    //call recursive back-end function
  }

  setupActionBar() {
    this.ishtar365Actions.buttonActions = [
      new ContextMenuAction<unknown>({
        label: this.translations.getTranslation$('save'),
        action: () => this.onSave(),
        disabled:
          this.isSaving$.asObservable() || this.hasErrors || !this.hasChanges,
        icon: 'save',
        iconOutline: true,
      }),
      new ContextMenuAction<unknown>({
        label: this.translations.getTranslation$('run'),
        action: () => this.runSync(),
        disabled: this.isSaving$.asObservable() || !this.config,
        icon: 'save',
        iconOutline: true,
      }),
    ];
  }

  getFieldDisplayName(index: number) {
    const contactFieldId = (
      this.mappingForm.get('mappingRows') as FormArray
    ).controls[index].get('contactFieldId')?.value;
    return this.contactDetailFields.find((c) => c.id == contactFieldId)
      ?.displayName;
  }
  getRoleDisplayName(index: number) {
    return (this.roleForm.get('roleRows') as FormArray).controls[index].get(
      'roleValue'
    )?.value;
  }
  getAliases(index: number) {
    return (this.departmentForm.get('departmentRows') as FormArray).controls[
      index
    ].get('alias')?.value;
  }
  removeMapping(index: number) {
    this.fieldMappings.at(index).get('azureFieldId')?.setValue('');
  }
  addAlias(event: MatChipInputEvent, index: number): void {
    const value = (event.value || '').trim();
    if (value) {
      (
        (this.departmentForm.get('departmentRows') as FormArray).controls[
          index
        ].get('alias')?.value as string[]
      ).push(value);
    }
    event.chipInput!.clear();
  }

  removeAlias(aliasToRemove: string, rowIndex: number): void {
    const index = (
      (this.departmentForm.get('departmentRows') as FormArray).controls[
        rowIndex
      ].get('alias')?.value as string[]
    ).indexOf(aliasToRemove);

    if (index >= 0) {
      (
        (this.departmentForm.get('departmentRows') as FormArray).controls[
          rowIndex
        ].get('alias')?.value as string[]
      ).splice(index, 1);
    }
  }
  editAlias(aliasToEdit: string, event: MatChipEditedEvent, rowIndex: number) {
    const value = event.value.trim();
    if (!value) {
      this.removeAlias(aliasToEdit, rowIndex);
      return;
    }
    const index = (
      (this.departmentForm.get('departmentRows') as FormArray).controls[
        rowIndex
      ].get('alias')?.value as string[]
    ).indexOf(aliasToEdit);
    if (index >= 0) {
      const aliasToEdit = (
        this.departmentForm.get('departmentRows') as FormArray
      ).controls[rowIndex].get('alias')?.value as string[];
      if (aliasToEdit) {
        aliasToEdit[index] = value;
      }
    }
  }
  toggleExtraDepConfig(depConfigVisible: boolean) {
    this.extraDepConfig = depConfigVisible;
  }
  toggleUseDelimter(useDelimiter: boolean) {
    this.useDelimiter = useDelimiter;
    if (useDelimiter) {
      this.delimiterFormControl.setValidators([
        Validators.maxLength(1),
        Validators.minLength(1),
        Validators.required,
      ]);
    } else {
      this.delimiterFormControl.patchValue('');
      this.delimiterFormControl.removeValidators([
        Validators.maxLength(1),
        Validators.minLength(1),
        Validators.required,
      ]);
      this.delimiterFormControl.markAsPristine();
    }
    this.delimiterFormControl.updateValueAndValidity();
  }
  addRole() {
    this.roleMappings.push(
      new FormGroup({
        roleValue: new FormControl('', Validators.required),
      })
    );
  }
  removeRole(index: number) {
    this.roleMappings.removeAt(index);
  }
  removeDepartment(index: number) {
    this.departmentMappings.removeAt(index);
  }
  addDepartment() {
    (this.departmentForm.get('departmentRows') as FormArray).push(
      new FormGroup({
        name: new FormControl('', Validators.required),
        supervisor: new FormControl('', Validators.required),
        alias: new FormControl([]),
        departmentTelNum: new FormControl('', Validators.required),
      })
    );
  }

  selectActiveDepartment(dep: FormGroup) {
    this.selectedGroupSyncDep = dep;
    //  this.ishtar365.getGraphGroups((dep.value.groupIds as GraphGroup[]).map(g => g.id));
  }

  public static addNewDepConfig(group: FormArray) {
    const newControl = new FormGroup({
      id: new FormControl<string | null>(v4()),
      departmentName: new FormControl('New Department', Validators.required),
      supervisor: new FormControl('', Validators.required),
      groupIds: new FormControl(
        [],
        [Validators.required, Validators.minLength(1)]
      ),
      children: new FormArray([]),
    });
    group.push(newControl);
    return newControl;
  }

  addGroupToDepartment(g: GraphGroup) {
    if (this.selectedGroupSyncDep) {
      this.selectedGroupSyncDep
        .get('groupIds')
        ?.setValue([
          ...(this.selectedGroupSyncDep.get('groupIds')?.value as string[]),
          g,
        ]);
    }
  }

  removeDepConfig(i: number) {
    const arr = [
      ...(this.selectedGroupSyncDep?.controls.groupIds.value as string[]),
    ];
    arr.splice(i, 1);
    this.selectedGroupSyncDep?.controls.groupIds.setValue(arr);
  }

  onSave() {
    if (this.hasErrors) {
      this.showErrorSnack();
      return undefined;
    }
    const config = new PermanenceAppConfig({
      departmentDelimiter: this.useDelimiter
        ? this.delimiterFormControl.value!
        : undefined,
      typeOfSync: this.typeOfSyncControl.value!,
      userParameters: this.mappingForm.value.mappingRows.map((m: any) => ({
        aADValue: this.azureFields.find((a) => a.id == m.azureFieldId)
          ?.internalName,
        ishtarValue: this.contactDetailFields.find(
          (c) => c.id == m.contactFieldId
        )?.internalName,
        valueType: 'string',
        isCustomSecurityAttribute: this.azureFields.find(
          (a) => a.id == m.azureFieldId
        )?.customSecurityAttribute,
        isExtensionAttribute: this.azureFields.find(
          (a) => a.id == m.azureFieldId
        )?.extensionAttribute,
      })),
      groupParameters: {
        parentGroup: this.selectedGroup!,
      },
      departmentParameters: this.departmentForm.value.departmentRows.map(
        (d: any) => ({
          name: d.name,
          supervisor: this.users.find((u) => u?.user?.id == d.supervisor)!,
          alias: d.alias,
          departmentTelNum: d.departmentTelNum,
        })
      ),
      roleMapping: {
        roles: this.roleForm.value.roleRows.map((r: any) => ({
          role: r.roleValue,
          isSupervisor: r.isSupervisor,
        })),
        basedOnField:
          this.azureFields.find((a) => a.id == this.roleForm.value.basedOnField)
            ?.internalName ?? undefined,
        isCustomSecurityAttribute: this.azureFields.find(
          (a) => a.id == this.roleForm.value.basedOnField
        )!.customSecurityAttribute,
        isExtensionAttribute: this.azureFields.find(
          (a) => a.id == this.roleForm.value.basedOnField
        )!.extensionAttribute,
      },
      departmentUserConfig: {
        departmentMapping: {
          basedOnField:
            this.azureFields.find(
              (a) =>
                a.id ==
                this.departmentUserMappingForm.value.departmentMapping
                  .basedOnField
            )?.internalName ?? undefined,
          nameValue:
            this.departmentUserMappingForm.value.departmentMapping.nameValue,
          isCustomSecurityAttribute: this.azureFields.find(
            (a) =>
              a.id ==
              this.departmentUserMappingForm.value.departmentMapping
                .basedOnField
          )!.customSecurityAttribute,
          isExtensionAttribute: this.azureFields.find(
            (a) =>
              a.id ==
              this.departmentUserMappingForm.value.departmentMapping
                .basedOnField
          )!.extensionAttribute,
        },
        departmentNumberMapping: {
          basedOnField:
            this.azureFields.find(
              (a) =>
                a.id == this.departmentNumberMappingFormGroup.value.basedOnField
            )?.internalName ?? undefined,
          isCustomSecurityAttribute: this.azureFields.find(
            (a) =>
              a.id == this.departmentNumberMappingFormGroup.value.basedOnField
          )!.customSecurityAttribute,
          isExtensionAttribute: this.azureFields.find(
            (a) =>
              a.id == this.departmentNumberMappingFormGroup.value.basedOnField
          )!.extensionAttribute,
        },
        departmentNameMapping: {
          basedOnField:
            this.azureFields.find(
              (a) =>
                a.id ==
                this.departmentUserMappingForm.value.departmentNameMapping
                  .basedOnField
            )?.internalName ?? undefined,
          isCustomSecurityAttribute: this.azureFields.find(
            (a) =>
              a.id ==
              this.departmentUserMappingForm.value.departmentNameMapping
                .basedOnField
          )!.customSecurityAttribute,
          isExtensionAttribute: this.azureFields.find(
            (a) =>
              a.id ==
              this.departmentUserMappingForm.value.departmentNameMapping
                .basedOnField
          )!.extensionAttribute,
        },
      },
      intervalParameters: {
        interval: this.intervalForm.value.interval,
        startTime: this.intervalForm.value.startTime,
        day: this.days.findIndex((d) => d.id == this.intervalForm.value.day),
      },
      adminConfig: {
        basedOnField:
          this.azureFields.find(
            (a) => a.id == this.adminForm.value.basedOnField
          )?.internalName ?? undefined,
        nameValue: this.adminForm.value.nameValue,
        isCustomSecurityAttribute: this.azureFields.find(
          (a) => a.id == this.adminForm.value.basedOnField
        )!.customSecurityAttribute,
        isExtensionAttribute: this.azureFields.find(
          (a) => a.id == this.adminForm.value.basedOnField
        )!.extensionAttribute,
      },
      groupsDepartmentConfig: {
        departments: this.groupSyncDepartmentDefinitions.value?.map((v) => ({
          id: v.id,
          departmentName: v.departmentName,
          supervisor: v.supervisor,
          groupIds: v.groupIds,
          children: v.children,
        })),
      },
      departmentWildCard:
        this.wildCardForm.value.nameValue.trim() != ''
          ? {
              basedOnField:
                this.azureFields.find(
                  (a) => a.id == this.wildCardForm.value.basedOnField
                )?.internalName ?? undefined,
              nameValue: this.wildCardForm.value.nameValue,
              isCustomSecurityAttribute: this.azureFields.find(
                (a) => a.id == this.wildCardForm.value.basedOnField
              )!.customSecurityAttribute,
              isExtensionAttribute: this.azureFields.find(
                (a) => a.id == this.wildCardForm.value.basedOnField
              )!.extensionAttribute,
            }
          : undefined,
      groupsAdminADGroups: this.adminGroupsControl.value ?? undefined,
    });

    const complete = new Subject<void>();
    this.ishtar365
      .updateAADSyncConfig('Ishtar.Permanence', PermanenceAppConfig, config)
      .subscribe(() => {
        complete.next();
        complete.complete();
      });
    this.loaderService.startLoading('Saving config...', () => complete);
    return complete.asObservable();
  }

  runSync() {
    if (this.hasErrors) {
      this.showErrorSnack();
      return;
    }
    if (this.hasChanges) {
      this.onSave()?.subscribe(() => {
        this.loaderService.startLoading(
          this.translations.getTranslation('runningSync') + '...',
          () => {
            return this.ishtar365.runSync('Ishtar.Permanence');
          }
        );
      });
    } else
      this.loaderService.startLoading(
        this.translations.getTranslation('runningSync') + '...',
        () => {
          return this.ishtar365.runSync('Ishtar.Permanence');
        }
      );
  }

  showErrorSnack() {
    this.snackBar.open('Missing configuration, please retry', undefined, {
      panelClass: 'app-notification-error',
      duration: 5000,
    });
    this.mappingForm.markAllAsTouched();
    this.roleForm.markAllAsTouched();
    this.departmentForm.markAllAsTouched();
    this.groupForm.markAllAsTouched();
    this.intervalForm.markAllAsTouched();
    this.delimiterFormControl.markAllAsTouched();
  }

  addAdminGroup(group: GraphGroup) {
    const value = this.adminGroupsControl.value;
    this.adminGroupsControl.setValue([
      ...(value ?? []),
      { id: group.id, name: group.displayName || '' },
    ]);
    this.adminGroupsFilterControl.setValue('', { emitEvent: false });
  }

  deleteAdminGroup(group: { id: string; name: string }) {
    const config = new ConfirmDialogData({
      title: this.translations.getTranslation('caution'),
      description:
        this.translations.getTranslation('removeQuestion') +
        ' ' +
        this.translations.getTranslation('adminGroup') +
        ' ' +
        group.name +
        '?',
      descriptionInHtml: true,
      cancelBtnText: this.translations.getTranslation('cancel'),
      confirmBtnText: this.translations.getTranslation('confirm'),
      onConfirm: () => {
        const value = this.adminGroupsControl.value;
        if (value)
          this.adminGroupsControl.setValue(
            value.filter((g) => g.id != group.id)
          );
      },
    });
    this.matDialog.open(ConfirmPopupComponent, {
      data: config,
      autoFocus: true,
      closeOnNavigation: true,
    });
  }
}
