import { BaseEntity } from 'processdelight-angular-components';
import { TileAction } from '../enums/tile-action.enum';
import { TilePermission } from './tile-permission.model';

export class Tile extends BaseEntity {
  title!: string;
  tileAction!: string;
  tileActionType!: TileAction;
  iconUrl!: string;
  hideTileInView?: boolean;
  disabled?: boolean;
  permissions?: TilePermission[];
  position!: number;

  constructor(obj: Partial<Tile>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.tileActionType == 'string')
      this.tileActionType =
        TileAction[obj.tileActionType as keyof typeof TileAction];
    if (obj.permissions)
      this.permissions = obj.permissions
        .filter((p) => p.permission.groupUser)
        .map((p) => new TilePermission(p));
  }
}
