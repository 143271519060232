import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import {
  LoaderService,
  NotificationButtonComponent,
  UserInitialsComponent,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Subject,
  catchError,
  filter,
  first,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { FrameManagerService } from 'src/app/core/services/frame-manager.service';
import { Ishtar365Service } from 'src/app/core/services/ishtar365.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { CoreModule } from 'src/app/core/core.module';
import { AppFrame } from 'src/app/core/domain/models/app-frame.model';
import { lastRouteKey } from 'src/app/core/guards/initial-navigation.guard';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { Tenant } from 'src/app/shared/models/tenant.model';
import { ApplicationBarService } from 'src/app/shared/services/application-bar.service';
import { ApplicationService } from 'src/app/shared/services/application.service';

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [
    CoreModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    UserInitialsComponent,
    NotificationButtonComponent,
  ],
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {
  @Input() appName!: string;

  @Input() orgLogo?: string;
  @Input() userPhoto?: string;

  @Input() navColor = 'var(--processdelightBlue)';
  parent = document.body;
  bodyWidth = document.body.clientWidth;

  userLang = '';

  isMobile$ = new BehaviorSubject<boolean>(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(
      navigator.userAgent
    )
  );

  menuOpen = false;

  signedIn = false;

  activeTenantIds: string[] = [];

  destroy$ = new Subject<void>();

  activeApp$ = this.frameManagerService.activeApp$;

  get selectedTenant(): Tenant | undefined {
    return this.applicationService.selectedTenant;
  }

  get username(): string | undefined {
    return this.applicationService.msal.name;
  }

  get userMail(): string | undefined {
    return this.applicationService.msal.username;
  }

  get tenants(): Tenant[] {
    return this.applicationService.tenants.filter((t) =>
      this.activeTenantIds.includes(t.tenantId)
    );
  }

  get inTeams() {
    return this.applicationService.msal.runningOnTeams;
  }

  get openApps() {
    return this.frameManagerService.openApps;
  }

  get closedApps() {
    return this.frameManagerService.possibleApps.filter(
      (a) =>
        !this.frameManagerService.openApps.some((o) => o.appUrl == a.appUrl)
    );
  }

  get isAdmin$() {
    return this.userFacade.isAdmin$;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private readonly frameManagerService: FrameManagerService,
    private readonly applicationService: ApplicationService,
    private readonly ishtar365Service: Ishtar365Service,
    private readonly translationService: TranslationService,
    private readonly loader: LoaderService,
    private readonly router: Router,
    private readonly userFacade: UserFacade,
    private readonly applicationBarService: ApplicationBarService
  ) {}

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }

  ngOnInit(): void {
    if (location.href.includes('app-silent-signin')) return;
    if (!this.isMobile$.value)
      this.breakpointObserver
        .observe([Breakpoints.XSmall])
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) =>
          this.isMobile$.next(Object.values(result.breakpoints).some((b) => b))
        );
    this.applicationService.signedIn
      .pipe(
        filter((b) => !!b),
        first()
      )
      .subscribe(() => this.onSignedIn());
    this.userFacade.userInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userInfo) => {
        if (userInfo) this.userLang = userInfo.language ?? 'en';
        else this.userLang = '';
      });
    this.applicationService.msal.silentSignIn();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  backToIshtar365() {
    this.frameManagerService.backToIshtar365();
  }

  handleTabChange(app: string) {
    if (!app) return;
    if (app == 'Ishtar.365') this.backToIshtar365();
    else this.openApp(app);
  }

  openApp(app: string) {
    this.frameManagerService.openApp(app);
  }

  closeApp(app: string) {
    this.frameManagerService.closeApp(app);
  }

  goToSettings() {
    this.frameManagerService.backToIshtar365();
    this.router.navigate(['settings', 'tilepages']);
  }

  isTabExpanded(app: string, activeApp?: AppFrame) {
    if (app == 'Ishtar.365' && this.inTeams) return false;
    if (app == 'Ishtar.365' && !activeApp) return true;
    return app == activeApp?.appName;
  }

  languageChange(event: MatSelectChange) {
    this.userFacade.updateUserLanguage(event.value);
    this.frameManagerService.updateLanguage(event.value);
  }

  signIn() {
    this.applicationService.signIn();
  }
  signOut() {
    this.applicationService.signOut();
  }

  private onSignedIn() {
    this.signedIn = true;
    this.userFacade
      .getUserInfo$(
        this.applicationService.msal.username,
        this.applicationService.msal.name
      )
      .subscribe((userInfo) => {
        this.translationService.init();

        const lastTenantId = userInfo.lastTenantId;
        let tapPipe = (tenants: Tenant[]) => {
          if (lastTenantId && this.activeTenantIds.includes(lastTenantId))
            this.selectTenant(tenants.find((t) => t.tenantId == lastTenantId));
          else if (this.activeTenantIds.length == 1)
            this.selectTenant(
              tenants.find((t) => t.tenantId == this.activeTenantIds[0])
            );
          return of(true);
        };
        const lastRoute = localStorage.getItem(lastRouteKey);
        if (lastRoute?.includes('initialconfiguration'))
          tapPipe = () => {
            this.applicationService.startInitialConfiguration();
            return of(true);
          };
        this.loader.startLoading('Loading your organizations', () =>
          this.ishtar365Service
            .getActiveTenantIds(this.applicationService.msal.username)
            .pipe(
              tap((ids) => (this.activeTenantIds = ids ?? [])),
              switchMap(() => {
                if (
                  !lastRoute?.includes('initialconfiguration') &&
                  this.activeTenantIds.length == 0
                ) {
                  this.router.navigate(['no-apps']);
                  return this.applicationService.loadTenants();
                } else
                  return this.applicationService
                    .loadTenants()
                    .pipe(switchMap(tapPipe));
              })
            )
        );
      });
  }

  selectTenant(tenant: Tenant | undefined, tenantSwitch = false) {
    if (!tenant || this.selectedTenant?.tenantId == tenant.tenantId) return;
    if (tenantSwitch) this.applicationService.resetApplication();
    this.applicationService.setSelectedTenant(tenant);
    this.userFacade.updateUserLastTenantId(tenant.tenantId);
    this.launch();
  }

  launch() {
    if (!this.selectedTenant) return;
    this.loader.startLoading('Initializing data', () =>
      this.applicationService.initAppData().pipe(
        first(),
        tap((b) => {
          if (b)
            setTimeout(() => {
              this.router.navigate(['page']);
            }, 0);
        }),
        catchError(() => {
          setTimeout(() => {
            this.router.navigate(['startup-error']);
          }, 0);
          return of(undefined);
        })
      )
    );
  }

  onCandyBoxEnter() {
    this.applicationBarService.openMenu();
  }
  onCandyBoxLeave() {
    this.applicationBarService.closeMenu();
  }
  onCandyBoxClicked() {
    this.applicationBarService.toggleTilePage();
  }

  getUrl() {
    return this.router.url;
  }

  goToHome() {
    this.router.navigate(['/page/Home']);
  }

  setParentWidth(event: Event) {
    const image: HTMLImageElement = event.target as HTMLImageElement;
    const parentElement = image.parentElement;
    if (parentElement) {
      parentElement.style.width = `${image.offsetWidth}px`;
    }
  }
}
