import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FrameManagerService } from 'src/app/core/services/frame-manager.service';
import { CoreModule } from 'src/app/core/core.module';
import { selectedTenant$ } from 'src/app/core/data/data.observables';
import { ApplicationService } from 'src/app/shared/services/application.service';

@Component({
  selector: 'app-frame-manager',
  standalone: true,
  imports: [CoreModule],
  templateUrl: './frame-manager.component.html',
  styleUrls: ['./frame-manager.component.scss'],
})
export class FrameManagerComponent implements OnInit, OnDestroy {
  get openApps() {
    return this.frameManagerService.openApps;
  }

  activeApp$ = this.frameManagerService.activeApp$;

  iframes: HTMLIFrameElement[] = [];

  @ViewChild('frameContainer') frameContainer?: ElementRef<HTMLDivElement>;

  destroy$ = new Subject<void>();

  static instance?: FrameManagerComponent;

  constructor(
    private readonly frameManagerService: FrameManagerService,
    private readonly applicationService: ApplicationService
  ) {
    if (FrameManagerComponent.instance) {
      throw new Error('FrameManagerComponent already created');
    }
    FrameManagerComponent.instance = this;
  }

  ngOnInit(): void {
    this.frameManagerService.openApp$
      .pipe(takeUntil(this.destroy$))
      .subscribe((app) => {
        if (this.iframes.some((f) => f.src.includes(app.appUrl))) {
          this.iframes.forEach((f) => {
            f.style.opacity = '0';
            f.style.width = '0';
            f.style.height = '0';
          });
          const iframe = this.iframes.find((f) => f.src.includes(app.appUrl));
          iframe!.style.opacity = '1';
          iframe!.style.width = '100%';
          iframe!.style.height = '100%';
        } else {
          const iframe = document.createElement('iframe');
          const appURI = new URL(app.appUrl);
          appURI.searchParams.set(
            'tenantId',
            this.applicationService.selectedTenant?.tenantId ?? ''
          );
          appURI.searchParams.set(
            'loginHint',
            this.applicationService.msal.username
          );
          appURI.searchParams.set('state', new Date().valueOf().toString());
          iframe.src = appURI.toString();
          iframe.allow = 'clipboard-read *; clipboard-write *';
          iframe.style.position = 'absolute';
          iframe.style.top = '0';
          iframe.style.left = '0';
          iframe.style.width = '0';
          iframe.style.height = '0';
          iframe.style.border = 'none';
          iframe.style.zIndex = '1';
          iframe.style.transition = 'opacity 0.3s ease-in-out';
          iframe.style.opacity = '0';
          iframe.style.display = 'block';
          iframe.id = app.appName;
          iframe.onload = () => {
            app.window = iframe.contentWindow ?? undefined;
            if (
              this.frameManagerService.activeApp$.value?.appName == app.appName
            ) {
              iframe.style.opacity = '1';
              iframe.style.width = '100%';
              iframe.style.height = '100%';
            }
          };
          this.iframes.push(iframe);
          this.frameContainer?.nativeElement.appendChild(iframe);
        }
      });
    this.frameManagerService.closeApp$
      .pipe(takeUntil(this.destroy$))
      .subscribe((app) => {
        const iframe = this.iframes.find((f) => f.src.includes(app.appUrl));
        if (iframe) {
          iframe.style.opacity = '0';
          setTimeout(() => {
            iframe.remove();
            this.iframes = this.iframes.filter((f) => f != iframe);
          }, 300);
        }
      });

    selectedTenant$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.iframes.forEach((f) => {
        f.remove();
      });
      this.iframes = [];
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.iframes.forEach((i) => i.remove());
    this.iframes = [];
  }
}
