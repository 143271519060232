<div class="tiles-settings-wrapper d-flex flex-column">
  <div class="mt-2 d-flex flex-sm-row flex-xs-row flex-column min-height-0">
    <div class="me-2 pb-2">
      <mat-card class="mh-100">
        <mat-card-header class="d-flex flex-row justify-content-between">
          <mat-card-title
            *ngIf="
              getTranslation$('manageTilePages') | async as manageTilePagesLabel
            "
          >
            {{ manageTilePagesLabel }}
          </mat-card-title>
          <button
            mat-mini-fab
            color="primary"
            (click)="addTilePage()"
            class="clickable"
            class="me-2"
          >
            <mat-icon> add </mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content class="min-height-0 scrollbar overflow-auto">
          <mat-action-list *ngIf="tilePages" class="min-height-0">
            <mat-list-item
              *ngFor="let page of tilePages.controls"
              [class.active]="getId(activeTilePage) === getId(page)"
              (click)="selectTilePage(page)"
            >
              <div
                class="d-flex flex-row justify-content-between align-items-center"
              >
                <div class="text-truncate" [style.max-width.rem]="10">
                  {{ getTitle(page) }}
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <ng-container *ngIf="getHomepageControl(page).value">
                    <mat-icon class="material-icons-outlined">home</mat-icon>
                  </ng-container>
                  <ng-container
                    *ngIf="isTilePageDeleted(page); else tilePageErrors"
                  >
                    <mat-icon class="material-icons-outlined warn"
                      >delete</mat-icon
                    >
                  </ng-container>
                  <ng-template #tilePageErrors>
                    <mat-icon
                      *ngIf="!isTilePageValid(page)"
                      class="material-icons-outlined"
                      >error_outline</mat-icon
                    >
                  </ng-template>
                </div>
              </div>
            </mat-list-item>
          </mat-action-list>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="me-2 pb-2">
      <mat-card *ngIf="activeTilePage" class="position-relative mh-100">
        <button
          mat-icon-button
          [color]="isTilePageDeleted(activeTilePage) ? 'primary' : 'warn'"
          class="position-absolute delete-button"
          (click)="toggleDeleteTilePage(activeTilePage)"
        >
          <mat-icon class="material-icons-outlined">{{
            isTilePageDeleted(activeTilePage) ? "delete_forever" : "delete"
          }}</mat-icon>
        </button>
        <mat-card-header
          class="d-flex flex-row justify-content-between flex-shrink-0"
        >
          <mat-card-title
            *ngIf="getTranslation$('tilePage') | async as tilePageLabel"
          >
            {{ tilePageLabel }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="min-height-0 d-flex">
          <div class="d-flex flex-column min-height-0">
            <mat-form-field
              class="ps-2 pe-2"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>
                <ng-container
                  *ngIf="getTranslation$('title') | async as titleLabel"
                  >{{ titleLabel }}
                </ng-container>
              </mat-label>
              <input
                #titleTilePage
                type="text"
                matInput
                [formControl]="getTitleControl(activeTilePage)"
              />
            </mat-form-field>
            <div class="ms-2 me-2 mb-2">
              <button
                mat-raised-button
                color="primary"
                [disabled]="getHomepageControl(activeTilePage).value"
                (click)="updateHomepage(activeTilePage)"
              >
                <ng-container
                  *ngIf="
                    getTranslation$('makeHomepage') | async as makeHomepageLabel
                  "
                  >{{ makeHomepageLabel }}
                </ng-container>
              </button>
            </div>
            <div class="ms-2 me-2">
              <mat-button-toggle-group
                [formControl]="getTypeControl(activeTilePage)"
                (change)="updateIntranetUrlValidity(activeTilePage)"
              >
                <mat-button-toggle [value]="TilePageType.Default"
                  >Default</mat-button-toggle
                >
                <mat-button-toggle [value]="TilePageType.Intranet"
                  >Intranet</mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>
            <div class="mt-3 ps-2 pe-2">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                *ngIf="
                  getTypeControl(activeTilePage).value === TilePageType.Intranet
                "
              >
                <mat-label>
                  <ng-container
                    *ngIf="
                      getTranslation$('intranetUrl') | async as intranetUrlLabel
                    "
                    >{{ intranetUrlLabel }}
                  </ng-container>
                </mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="getIntranetUrlControl(activeTilePage)"
                />
              </mat-form-field>
            </div>
            <div
              class="d-flex flex-row align-items-center justify-content-between ms-3 me-1 mb-2"
            >
              <div
                class="list-header"
                *ngIf="
                  getTranslation$('manageSegments')
                    | async as manageSegmentsLabel
                "
              >
                {{ manageSegmentsLabel }}
              </div>
              <button
                mat-mini-fab
                color="primary"
                (click)="addSegment()"
                class="clickable"
                class="me-2"
              >
                <mat-icon> add </mat-icon>
              </button>
            </div>
            <div class="scrollbar overflow-auto">
              <mat-action-list
                cdkDropList
                (cdkDropListDropped)="
                  reorder($event, getSegments(activeTilePage))
                "
              >
                <mat-list-item
                  *ngFor="let segment of getSegments(activeTilePage).controls"
                  [class.active]="getId(activeSegment) === getId(segment)"
                  (click)="selectSegment(segment)"
                  cdkDrag
                  ><div
                    class="d-flex flex-row justify-content-between align-items-center"
                  >
                    <div class="text-truncate" [style.max-width.rem]="10">
                      {{ getTitle(segment) }}
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <ng-container
                        *ngIf="isSegmentDeleted(segment); else segmentErrors"
                      >
                        <mat-icon class="material-icons-outlined warn"
                          >delete</mat-icon
                        >
                      </ng-container>
                      <ng-template #segmentErrors>
                        <mat-icon
                          *ngIf="!isSegmentValid(segment)"
                          class="material-icons-outlined"
                          >error_outline</mat-icon
                        >
                      </ng-template>
                      <mat-icon cdkDragHandle>drag_indicator</mat-icon>
                    </div>
                  </div>
                </mat-list-item>
              </mat-action-list>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="me-2 pb-2">
      <mat-card *ngIf="activeSegment" class="position-relative mh-100">
        <button
          mat-icon-button
          [color]="isSegmentDeleted(activeSegment) ? 'primary' : 'warn'"
          class="position-absolute delete-button"
          (click)="toggleDeleteSegment(activeSegment)"
        >
          <mat-icon class="material-icons-outlined">{{
            isSegmentDeleted(activeSegment) ? "delete_forever" : "delete"
          }}</mat-icon>
        </button>
        <mat-card-header
          class="d-flex flex-row justify-content-between flex-shrink-0"
        >
          <mat-card-title
            *ngIf="getTranslation$('segment') | async as segmentLabel"
          >
            {{ segmentLabel }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="min-height-0 d-flex">
          <div class="d-flex flex-column min-height-0">
            <mat-form-field
              class="ps-2 pe-2"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>
                <ng-container
                  *ngIf="getTranslation$('title') | async as titleLabel"
                  >{{ titleLabel }}
                </ng-container>
              </mat-label>
              <input
                #titleSegment
                type="text"
                matInput
                [formControl]="getTitleControl(activeSegment)"
              />
            </mat-form-field>
            <mat-checkbox
              [formControl]="getDisabledControl(activeSegment)"
              *ngIf="getTranslation$('disabled') | async as disabledLabel"
              >{{ disabledLabel }}
            </mat-checkbox>
            <mat-checkbox
              [formControl]="getHideTitleControl(activeSegment)"
              *ngIf="
                getTranslation$('hideSegmentTitle') | async as hideTitleLabel
              "
              >{{ hideTitleLabel }}
            </mat-checkbox>
            <div
              class="d-flex flex-row align-items-center justify-content-between ms-3 me-1 mb-2"
            >
              <div
                class="list-header"
                *ngIf="
                  getTranslation$('manageTiles') | async as manageTilesLabel
                "
              >
                {{ manageTilesLabel }}
              </div>
              <button
                mat-mini-fab
                color="primary"
                (click)="addTile()"
                class="clickable"
                class="me-2"
              >
                <mat-icon> add </mat-icon>
              </button>
            </div>
            <div class="scrollbar overflow-auto">
              <mat-action-list
                cdkDropList
                (cdkDropListDropped)="reorder($event, getTiles(activeSegment))"
              >
                <mat-list-item
                  *ngFor="let tile of getTiles(activeSegment).controls"
                  [class.active]="getId(activeTile) === getId(tile)"
                  (click)="selectTile(tile)"
                  cdkDrag
                >
                  <div
                    class="d-flex flex-row justify-content-between align-items-center"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <div class="text-truncate" [style.max-width.rem]="10">
                        {{ getTitle(tile) }}
                      </div>
                      <mat-icon
                        *ngIf="getPermissions(tile).controls.length"
                        class="material-icons-outlined"
                        >lock</mat-icon
                      >
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <ng-container
                        *ngIf="isTileDeleted(tile); else tileErrors"
                      >
                        <mat-icon class="material-icons-outlined warn"
                          >delete</mat-icon
                        >
                      </ng-container>
                      <ng-template #tileErrors>
                        <mat-icon
                          *ngIf="!isTileValid(tile)"
                          class="material-icons-outlined"
                          >error_outline</mat-icon
                        >
                      </ng-template>
                      <mat-icon cdkDragHandle>drag_indicator</mat-icon>
                    </div>
                  </div>
                </mat-list-item>
              </mat-action-list>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="me-2 pb-2">
      <mat-card *ngIf="activeTile" class="position-relative tile-card">
        <button
          mat-icon-button
          [color]="isTileDeleted(activeTile) ? 'primary' : 'warn'"
          class="position-absolute delete-button"
          (click)="toggleDeleteTile(activeTile)"
        >
          <mat-icon class="material-icons-outlined">{{
            isTileDeleted(activeTile) ? "delete_forever" : "delete"
          }}</mat-icon>
        </button>
        <mat-card-header
          class="d-flex flex-row justify-content-between flex-shrink-0"
        >
          <mat-card-title *ngIf="getTranslation$('tile') | async as tileLabel">
            {{ tileLabel }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="min-height-0 d-flex">
          <div class="d-flex flex-column min-height-0 min-width-0">
            <mat-form-field
              class="ps-2 pe-2"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>
                <ng-container
                  *ngIf="getTranslation$('title') | async as titleLabel"
                  >{{ titleLabel }}
                </ng-container>
              </mat-label>
              <input
                #titleTile
                type="text"
                matInput
                [formControl]="getTitleControl(activeTile)"
              />
            </mat-form-field>
            <mat-checkbox
              [formControl]="getDisabledControl(activeTile)"
              *ngIf="getTranslation$('disabled') | async as disabledLabel"
              >{{ disabledLabel }}
            </mat-checkbox>
            <div class="ms-2 me-2 mb-2">
              <mat-button-toggle-group
                [value]="getTypeControl(activeTile).value"
                (change)="
                  checkTilePermissions($event.value, activeTile);
                  getTileActionControl(activeTile).patchValue('')
                "
              >
                <mat-button-toggle [value]="TileAction.TilePage"
                  >Tile page</mat-button-toggle
                >
                <mat-button-toggle [value]="TileAction.Link"
                  >Link</mat-button-toggle
                >
                <mat-button-toggle [value]="TileAction.IshtarApp"
                  >Ishtar app</mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>
            <div
              *ngIf="getTypeControl(activeTile).value === TileAction.TilePage"
              class="ps-2 pe-2"
            >
              <ng-container *ngIf="tilePages?.length">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label
                    ><ng-container
                      *ngIf="
                        getTranslation$('tilePage') | async as tilePageLabel
                      "
                      >{{ tilePageLabel }}
                    </ng-container></mat-label
                  >
                  <mat-select
                    [formControl]="getTileActionControl(activeTile)"
                    panelClass="scrollbar"
                    (opened)="searchInput.focus()"
                    (closed)="searchInput.value = ''"
                  >
                    <div class="d-flex flex-column h-100">
                      <mat-form-field
                        class="no-subscript-wrapper"
                        (click)="$event.stopPropagation()"
                      >
                        <mat-label>Search...</mat-label>
                        <input matInput type="text" #searchInput />
                      </mat-form-field>
                      <div class="overflow-auto scrollbar">
                        <mat-option
                          *ngFor="let page of possibleTilePageTiles"
                          [value]="getId(page)"
                          [hidden]="
                            !getTitle(page)
                              .toLowerCase()
                              .includes(searchInput.value.toLowerCase())
                          "
                          >{{ getTitle(page) }}</mat-option
                        >
                      </div>
                    </div>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </div>
            <div
              *ngIf="getTypeControl(activeTile).value === TileAction.IshtarApp"
              class="ps-2 pe-2"
            >
              <ng-container *ngIf="appsWithSubscription$ | async as apps">
                <mat-form-field
                  *ngIf="apps.length; else noAppsWithSubscription"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label
                    ><ng-container
                      *ngIf="getTranslation$('app') | async as appLabel"
                      >{{ appLabel }}
                    </ng-container></mat-label
                  >
                  <mat-select
                    [formControl]="getTileActionControl(activeTile)"
                    panelClass="scrollbar"
                    (opened)="searchInput.focus()"
                    (closed)="searchInput.value = ''"
                  >
                    <div class="d-flex flex-column h-100">
                      <mat-form-field
                        class="no-subscript-wrapper"
                        (click)="$event.stopPropagation()"
                      >
                        <mat-label>Search...</mat-label>
                        <input matInput type="text" #searchInput />
                      </mat-form-field>
                      <div class="overflow-auto scrollbar">
                        <mat-option
                          *ngFor="let app of apps"
                          [value]="app.productId.toString()"
                          [hidden]="
                            !app.name
                              .toLowerCase()
                              .includes(searchInput.value.toLowerCase())
                          "
                          >{{ app.name }}!</mat-option
                        >
                      </div>
                    </div>
                  </mat-select>
                </mat-form-field>
                <ng-template #noAppsWithSubscription>
                  <div>There are no other apps available</div>
                </ng-template>
              </ng-container>
            </div>
            <div
              *ngIf="getTypeControl(activeTile).value === TileAction.Link"
              class="mt-3 ps-2 pe-2"
            >
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>
                  <ng-container
                    *ngIf="getTranslation$('url') | async as urlLabel"
                    >{{ urlLabel }}
                  </ng-container>
                </mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="getTileActionControl(activeTile)"
                />
              </mat-form-field>
            </div>
            <ng-container
              *ngIf="
                getTypeControl(activeTile).value === TileAction.Link ||
                getTypeControl(activeTile).value === TileAction.TilePage
              "
            >
              <div
                class="border-dashed border-gray rounded upload-zone m-2 d-flex flex-column align-items-center position-relative"
                (dragover)="dragStart($event)"
              >
                <div
                  [hidden]="!dragging"
                  class="position-absolute w-100 h-100 rounded drop-zone"
                  (drop)="imageDropped($event, activeTile)"
                  (dragleave)="dragStop($event)"
                ></div>
                <input
                  #iconInput
                  type="file"
                  hidden
                  accept="image/*"
                  (change)="onIconInputChange($event, activeTile)"
                />
                <mat-icon class="material-icons-outlined upload-icon"
                  >cloud_upload</mat-icon
                >
                <div
                  class="d-flex flex-column flex-nowrap align-items-center m-2"
                >
                  <div
                    *ngIf="
                      getTranslation$('dragAndDropYourFile')
                        | async as dragAndDropLabel
                    "
                  >
                    {{ dragAndDropLabel }}
                  </div>
                  <div *ngIf="getTranslation$('OR') | async as orLabel">
                    {{ orLabel.toLowerCase() }}
                  </div>
                  <a
                    mat-raised-button
                    *ngIf="getTranslation$('browse') | async as browseLabel"
                    (click)="iconInput.click()"
                    color="primary"
                  >
                    {{ browseLabel }}
                  </a>
                </div>
              </div>
              <ng-container *ngIf="activeIconUrl">
                <div class="d-flex flex-row align-items-start">
                  <img
                    class="icon m-2"
                    [src]="activeIconUrl"
                    alt="Tile icon"
                  />
                  <button
                    mat-icon-button
                    (click)="clearIconInput(activeTile)"
                    class="clear-icon"
                  >
                    <mat-icon class="material-icons-outlined">cancel</mat-icon>
                  </button>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="getTypeControl(activeTile).value === TileAction.IshtarApp"
            >
              <div
                class="flex-grow-0 d-flex flex-row horizontal-scrollbar overflow-auto m-2"
              >
                <div
                  class="m-1 clickable position-relative"
                  *ngFor="let icon of getAppIcons$(activeTile) | async"
                  [class.active]="getIconUrlControl(activeTile).value === icon"
                  (click)="
                    getIconUrlControl(activeTile).patchValue(icon);
                    getIconUrlControl(activeTile).markAsDirty()
                  "
                >
                  <mat-icon
                    class="position-absolute app-icon-checked material-icons-outlined"
                    *ngIf="getIconUrlControl(activeTile).value === icon"
                    >check_circle</mat-icon
                  >
                  <img
                    [src]="icon"
                    (error)="appIconError($event)"
                    alt="app icon"
                    width="64"
                    height="64"
                  />
                </div>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="pb-2" *ngIf="activeTile">
      <mat-card class="position-relative mh-100">
        <mat-card-header class="d-flex flex-row justify-content-between">
          <mat-card-title
            *ngIf="
              getTranslation$('managePermissions')
                | async as managePermissionsLabel
            "
          >
            {{ managePermissionsLabel }}
          </mat-card-title>
          <button
            mat-mini-fab
            color="primary"
            (click)="addTilePermission()"
            class="clickable ms-2 me-2"
          >
            <mat-icon> add </mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content class="min-height-0 d-flex">
          <div class="d-flex flex-column min-height-0">
            <div class="scrollbar overflow-auto">
              <mat-action-list>
                <mat-list-item
                  *ngFor="
                    let tilePermission of getPermissions(activeTile).controls
                  "
                  [class.active]="
                    getId(activeTilePermission) === getId(tilePermission)
                  "
                  (click)="selectTilePermission(tilePermission)"
                  ><div
                    class="d-flex flex-row justify-content-between align-items-center"
                  >
                    <div>
                      {{
                        getGroupUserControl(tilePermission).value?.displayName
                      }}
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <ng-container
                        *ngIf="
                          isTilePermissionDeleted(tilePermission);
                          else tilePermissionErrors
                        "
                      >
                        <mat-icon class="material-icons-outlined warn"
                          >delete</mat-icon
                        >
                      </ng-container>
                      <ng-template #tilePermissionErrors>
                        <mat-icon
                          *ngIf="!isTilePermissionValid(tilePermission)"
                          class="material-icons-outlined"
                          >error_outline</mat-icon
                        >
                      </ng-template>
                    </div>
                  </div></mat-list-item
                >
              </mat-action-list>
            </div>
            <ng-container *ngIf="activeTilePermission">
              <div
                class="d-flex flex-row align-items-center justify-content-between ms-3 me-1 mb-2"
              >
                <div
                  class="list-header"
                  *ngIf="
                    getTranslation$('permission') | async as permissionLabel
                  "
                >
                  {{ permissionLabel }}
                </div>
                <button
                  mat-icon-button
                  [color]="
                    isTilePermissionDeleted(activeTilePermission)
                      ? 'primary'
                      : 'warn'
                  "
                  (click)="toggleDeleteTilePermission(activeTilePermission)"
                  class="clickable delete-button"
                  [style.top]="0"
                  [style.right]="0"
                >
                  <mat-icon class="material-icons-outlined">
                    {{
                      isTilePermissionDeleted(activeTilePermission)
                        ? "delete_forever"
                        : "delete"
                    }}
                  </mat-icon>
                </button>
              </div>
              <mat-form-field
                class="ps-2 pe-2"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>
                  <ng-container
                    *ngIf="
                      getTranslation$('groupOrUser') | async as groupOrUserLabel
                    "
                    >{{ groupOrUserLabel }}
                  </ng-container>
                </mat-label>
                <mat-select
                  appearance="outline"
                  floatLabel="always"
                  [formControl]="getGroupUserControl(activeTilePermission)"
                  (opened)="searchInput.focus()"
                  (closed)="searchInput.value = ''"
                  [compareWith]="groupUserCompareFn"
                >
                  <div class="d-flex flex-column h-100">
                    <mat-form-field
                      class="no-subscript-wrapper"
                      (click)="$event.stopPropagation()"
                    >
                      <mat-label>Search...</mat-label>
                      <input matInput type="text" #searchInput />
                    </mat-form-field>
                    <div
                      class="overflow-auto scrollbar"
                      *ngIf="groupUsers$ | async as groupUsers"
                    >
                      <mat-option
                        *ngFor="let groupUser of groupUsers"
                        [value]="groupUser"
                        [hidden]="
                          !groupUser.displayName
                            ?.toLowerCase()
                            ?.includes(searchInput.value.toLowerCase())
                        "
                      >
                        {{ groupUser.displayName }}
                      </mat-option>
                    </div>
                  </div>
                </mat-select>
              </mat-form-field>
              <div class="ms-2 me-2 mb-2">
                <mat-radio-group
                  [formControl]="getPermissionTypeControl(activeTilePermission)"
                  class="d-flex flex-column"
                >
                  <mat-radio-button color="primary" value="View"
                    >View</mat-radio-button
                  >
                  <mat-radio-button
                    *ngIf="
                      getTypeControl(activeTile).value === TileAction.TilePage
                    "
                    color="primary"
                    value="FullControl"
                    >Full control</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
