import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  first,
  map,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { UserFacade } from '../store/user/user.facade';
import { Ishtar365Service } from './ishtar365.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService implements OnDestroy {
  translations$ = new BehaviorSubject<{ [key: string]: string }>({});

  private sub?: Subscription;

  constructor(
    private ishtar365: Ishtar365Service,
    private userFacade: UserFacade
  ) {}

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = undefined;
    }
  }

  init() {
    if (!this.sub)
      this.sub = this.userFacade.userInfo$
        .pipe(
          switchMap((user) =>
            this.ishtar365.getTranslations(user?.language ?? 'en')
          ),
          tap((translations) => this.translations$.next(translations))
        )
        .subscribe();
  }

  getTranslations() {
    return this.translations$.pipe(first());
  }

  hasLabel(label: string) {
    return Object.keys(this.translations$.value).includes(label);
  }

  getTranslation(label: string) {
    return this.translations$.value[label];
  }

  getTranslation$(label: string) {
    return this.translations$.pipe(map((t) => t[label]));
  }
}
