<div
  class="d-flex flex-row w-100 h-100 overflow-hidden position-relative"
  *ngIf="{
    navColor: navColor$ | async,
    navContrast: navContrast$ | async,
    signedIn: signedIn$ | async,
  } as data"
>
  <div
    [hidden]="!menuOpened"
    class="position-absolute h-100 bg-white z-index-5"
    [style.width]="
      userSettings.sideBarPinned || sideBarHover ? 'calc(100% - 56px)' : '100%'
    "
    [style.left]="userSettings.sideBarPinned || sideBarHover ? '56px' : '0px'"
  >
    <div
      class="position-absolute close-menu d-flex justify-content-center align-items-center clickable"
      (click)="toggleTilePage()"
    >
      <button mat-mini-fab color="primary">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div
      class="w-100 h-100 d-flex flex-column align-items-center overflow-auto scrollbar"
    >
      <div
        class="d-flex flex-column justify-content-center align-items-center mb-4"
        *ngFor="let segment of segments; let isFirst = first"
        [style.margin-top.px]="isFirst ? firstTopMargin : 0"
      >
        <div class="section-title mb-2 fw-bold" *ngIf="!segment.hideTitle">
          {{ segment.title }}
        </div>
        <div
          class="d-flex flex-row flex-wrap justify-content-center align-items-flex-start"
        >
          <div *ngFor="let tile of getTiles(segment)">
            <ng-container
              *ngIf="
                tile.tileActionType === TileAction.IshtarApp;
                else customTile
              "
            >
              <app-app-tile [tile]="tile"></app-app-tile>
            </ng-container>
            <ng-template #customTile>
              <app-custom-tile [tile]="tile"></app-custom-tile>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="position-relative pb-4"
    (mouseenter)="sideBarHover = true"
    (mouseleave)="sideBarHover = false"
    style="transition: max-width 0.2s ease-in-out"
    [style.max-width.px]="userSettings.sideBarPinned || sideBarHover ? 56 : 0"
  >
    <!-- <div
  class="position-absolute open-menu d-flex justify-content-center align-items-center clickable z-index-4"
  [class.pinned]="userSettings.sideBarPinned || sideBarHover"
  (click)="menuOpened = true"
>
  <mat-icon>chevron_right</mat-icon>
</div> -->
    <div
      class="pin-menu d-flex justify-content-center align-items-center clickable z-index-4"
      [class.pinned]="userSettings.sideBarPinned"
      [style.width.px]="userSettings.sideBarPinned || sideBarHover ? 56 : 0"
      (click)="togglePinned()"
    >
      <mat-icon [class.material-icons-outlined]="!userSettings.sideBarPinned"
        >push_pin</mat-icon
      >
    </div>
    <div
      class="d-flex flex-column align-items-center scrollbar tile-wrapper"
      [class.ps-2]="userSettings.sideBarPinned || sideBarHover"
      [class.pe-2]="userSettings.sideBarPinned || sideBarHover"
    >
      <ng-container *ngFor="let segment of segments">
        <ng-container *ngFor="let tile of getTiles(segment)">
          <div
            class="mb-2"
            *ngIf="tile.tileActionType !== TileAction.IshtarApp"
          >
            <app-custom-tile
              [tile]="tile"
              [size]="40"
              [intranet]="true"
              [inSidebar]="true"
            ></app-custom-tile>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="d-flex flex-column w-100 h-100 min-height-0">
    <processdelight-action-bar
      [buttonActions]="buttonActions"
      [iconActions]="iconActions"
      [navColor]="data.navColor ?? 'var(--processdelightBlue)'"
      [navContrast]="data.navContrast ?? '#fff'"
      [searchEnabled]="data.signedIn ?? false"
      [sideBarButtonEnabled]="false"
    ></processdelight-action-bar>
    <nav mat-tab-nav-bar [tabPanel]="tabPanel" [mat-stretch-tabs]="false">
      <ng-container *ngIf="getTranslation$('tilePages') | async as navLabel">
        <a
          class="text-uppercase"
          mat-tab-link
          [routerLink]="['tilepages']"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
          >{{ navLabel }}
        </a>
      </ng-container>
      <ng-container *ngIf="getTranslation$('licenses') | async as navLabel">
        <a
          class="text-uppercase"
          mat-tab-link
          [routerLink]="['licenses']"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
          >{{ navLabel }}</a
        >
      </ng-container>
      <ng-container *ngIf="getTranslation$('groups') | async as navLabel">
        <a
          class="text-uppercase"
          mat-tab-link
          [routerLink]="['groups']"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
          >{{ navLabel }}</a
        >
      </ng-container>
      <ng-container
        *ngIf="getTranslation$('personalization') | async as navLabel"
      >
        <a
          class="text-uppercase"
          mat-tab-link
          [routerLink]="['personalization']"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
          >{{ navLabel }}
        </a>
      </ng-container>
      <ng-container *ngIf="getTranslation$('skills') | async as navLabel">
        <a
          class="text-uppercase"
          mat-tab-link
          [routerLink]="['skills']"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
          >{{ navLabel }}
        </a>
      </ng-container>
      <!--<ng-container *ngIf="getTranslation$('workRegime') | async as navLabel">
      <a
        *ngIf="hasOoO$ | async"
        class="text-uppercase"
        mat-tab-link
        [routerLink]="['workregime']"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
        >{{ navLabel }}
      </a>
    </ng-container>-->
      <ng-container>
        <a
          class="text-uppercase"
          mat-tab-link
          [routerLink]="['azureSync']"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
          >Azure Sync
        </a>
      </ng-container>
      <!-- <ng-container
        *ngIf="getTranslation$('automationFlowList') | async as navLabel"
      >
        <a
          class="text-uppercase"
          mat-tab-link
          [routerLink]="['automationflow']"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
          >{{ navLabel }}
        </a>
      </ng-container> -->
    </nav>
    <mat-tab-nav-panel
      #tabPanel
      class="min-height-0 h-100 overflow-auto scrollbar"
    >
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>
