import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Day, GroupUser } from 'processdelight-angular-components';
import { map, tap } from 'rxjs/operators';
import { IshtarOrderDTO } from '../domain/dto/ishtar-order.dto';
import { TileAction } from '../domain/enums/tile-action.enum';
import { TilePageType } from '../domain/enums/tile-page-type.enum';
import { ContactDetailParam } from '../domain/models/contactDetailParam';
import { CustomSecurityAttribute } from '../domain/models/customSecurityAttribute';
import { GraphGroup } from '../domain/models/graph-group.model';
import { GraphUser } from '../domain/models/graph-user.model';
import { IshtarApp } from '../domain/models/ishtar-app.model';
import { IshtarLicense } from '../domain/models/ishtar-license.model';
import { IshtarOrder } from '../domain/models/ishtar-order.model';
import { IshtarOrganigramGroup } from '../domain/models/ishtar-organigram-group..model';
import { IshtarOrganizationAdmin } from '../domain/models/ishtar-organization-admin.model';
import { IshtarOrganization } from '../domain/models/ishtar-organization.model';
import { IshtarSubscription } from '../domain/models/ishtar-subscription.model';
import { IshtarUserLicenses } from '../domain/models/ishtar-user-licenses.model';
import { UserSkill } from '../domain/models/user-skill.model';
import { IshtarUser } from '../domain/models/ishtar-user.model';
import { Permission } from '../domain/models/permission.model';
import { SharepointSite } from '../domain/models/sharepoint-site.model';
import { Skill } from '../domain/models/skill.model';
import { TilePageSegment } from '../domain/models/tile-page-segment.model';
import { TilePage } from '../domain/models/tile-page.model';
import { Tile } from '../domain/models/tile.model';
import { UserSettings } from '../domain/models/user-settings.model';
import { WorkRegime } from '../domain/models/workRegime.model';
import { WorkRegimeDay } from '../domain/models/workRegimeDay.model';
import { WorkRegimeType } from '../domain/models/workRegimeType.model';
import { InterestGroup } from '../domain/models/interest-group.model';
import { Library } from '../domain/models/library.model';
import { FormTemplate } from '../domain/models/form-template.model';
import { MetadataParameter } from '../domain/models/metadata-parameter.model';

@Injectable({
  providedIn: 'root',
})
export class Ishtar365Service {
  apiBase = `${location.origin}/web`;
  constructor(private httpClient: HttpClient) {}

  registerSession(tenantId: string) {
    return this.httpClient.post(
      `${this.apiBase}/register?tenantId=${tenantId}`,
      {}
    );
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.apiBase}/keepalive`, {});
  }

  adminConsented(tenantId: string) {
    return this.httpClient.post<object>(
      `${this.apiBase}/organizations/${tenantId}/adminConsent`,
      {}
    );
  }
  lastAdminConsent(tenantId: string) {
    return this.httpClient.get<Date>(
      `${this.apiBase}/organizations/${tenantId}/adminConsent`
    );
  }
  setEnvironmentUrl(tenantId: string, environmentUrl: string) {
    return this.httpClient.post<object>(
      `${this.apiBase}/organizations/${tenantId}/environmentUrl`,
      { environmentUrl }
    );
  }
  hasEnvironmentUrl(tenantId: string) {
    return this.httpClient.get<boolean>(
      `${this.apiBase}/organizations/${tenantId}/hasEnvironmentUrl`
    );
  }
  setSharepointUrl(tenantId: string, sharepointUrl: string) {
    return this.httpClient.post<object>(
      `${this.apiBase}/organizations/${tenantId}/sharepointUrl`,
      { sharepointUrl }
    );
  }
  addOrganization(tenantId: string, name: string) {
    return this.httpClient.post<object>(`${this.apiBase}/organizations`, {
      tenantId,
      name,
    });
  }
  updateLanguage(language: string) {
    return this.httpClient.post<IshtarUser>(`${this.apiBase}/users/language`, {
      language,
    });
  }
  updateLastTenantId(tenantId: string) {
    return this.httpClient.post<IshtarUser>(
      `${this.apiBase}/users/lastTenantId`,
      { tenantId }
    );
  }
  getApps() {
    return this.httpClient.get<IshtarApp[]>(`${this.apiBase}/apps`);
  }
  getSubscriptions() {
    return this.httpClient.get<IshtarSubscription[]>(
      `${this.apiBase}/subscriptions`
    );
  }
  getAADSyncConfig<T>(
    appName: string,
    constructor: new (obj: Partial<T>) => T
  ) {
    return this.httpClient
      .get<T>(`${this.apiBase}/aadsync/${appName}`)
      .pipe(map((c) => new constructor(c)));
  }

  updateAADSyncConfig<T>(
    appName: string,
    constructor: new (obj: Partial<T>) => T,
    config: T
  ) {
    return this.httpClient
      .post<T>(`${this.apiBase}/aadsync/${appName}`, config)
      .pipe(map((c) => new constructor(c)));
  }

  getLicensesForApp(resetCache = false, appId: string) {
    let params = new HttpParams();
    if (resetCache) {
      params = params.append('resetCache', 'true');
    }

    return this.httpClient.get<IshtarLicense[]>(
      `${this.apiBase}/licenses/app/${appId}`,
      { params }
    );
  }

  getLicensesForUser(name?: string, resetCache = false) {
    let params = new HttpParams();
    if (resetCache) {
      params = params.append('resetCache', 'true');
    }

    return this.httpClient.get<IshtarUserLicenses>(
      `${this.apiBase}/licenses/user${name ? `?name=${name}` : ''}`,
      { params }
    );
  }

  addLicense(appId: string, email: string, name: string, admin: boolean) {
    return this.httpClient.post<IshtarLicense>(
      `${this.apiBase}/licenses/app/${appId}/user/${email}?admin=${admin}&name=${name}`,
      {}
    );
  }
  updateLicense(appId: string, email: string, admin: boolean) {
    return this.httpClient.patch<IshtarLicense>(
      `${this.apiBase}/licenses/app/${appId}/user/${email}?admin=${admin}`,
      {}
    );
  }
  removeLicense(appId: string, email: string) {
    return this.httpClient.delete<IshtarLicense>(
      `${this.apiBase}/licenses/app/${appId}/user/${email}`
    );
  }
  getOrganization(tenantId: string) {
    return this.httpClient.get<IshtarOrganization>(
      `${this.apiBase}/organizations/${tenantId}`
    );
  }
  getOrganizationAdmins() {
    return this.httpClient.get<IshtarOrganizationAdmin[]>(
      `${this.apiBase}/organizations/admins`
    );
  }
  updateOrganizationPersonalization(
    logo: string,
    navColor: string,
    navContrast: string
  ) {
    return this.httpClient.post<IshtarOrganization>(
      `${this.apiBase}/organizations/personalization`,
      {
        logo,
        navColor,
        navContrast,
      }
    );
  }
  getUser(email: string, name?: string) {
    return this.httpClient.get<IshtarUser>(
      `${this.apiBase}/users/${email}${name ? `?name=${name}` : ''}`
    );
  }
  getOrganigram(appName: string) {
    return this.httpClient.get<IshtarOrganigramGroup[]>(
      `${this.apiBase}/groups/app/${appName}/organigram`
    );
  }
  saveOrganigram(appName: string, groups: IshtarOrganigramGroup[]) {
    return this.httpClient.post<IshtarOrganigramGroup[]>(
      `${this.apiBase}/groups/app/${appName}/organigram`,
      groups
    );
  }
  getOrder(id: number) {
    return this.httpClient
      .get<IshtarOrderDTO>(`${this.apiBase}/orders/${id}`)
      .pipe(map((data) => new IshtarOrder(data)));
  }
  createSubscription(
    orderId: number,
    organization: IshtarOrganization,
    user: IshtarUser
  ) {
    return this.httpClient
      .post<IshtarSubscription>(`${this.apiBase}/subscriptions`, {
        orderId,
        organization,
        user,
      })
      .pipe(map((data) => new IshtarSubscription(data)));
  }
  getActiveTenantIds(mail: string) {
    return this.httpClient.get<string[]>(
      `${this.apiBase}/licenses/I365/${mail}`
    );
  }

  getUserSettings() {
    return this.httpClient
      .get(`${this.apiBase}/users/app/Ishtar.365/settings`)
      .pipe(map((c) => new UserSettings(c)));
  }
  updateUserSettings(settings: UserSettings) {
    return this.httpClient
      .post(`${this.apiBase}/users/app/Ishtar.365/settings`, {
        settings: JSON.stringify(settings),
      })
      .pipe(map((c) => new UserSettings(c)));
  }

  getGraphUsers(filter?: string) {
    return this.httpClient
      .get<GraphUser[]>(
        `${this.apiBase}/graphusers${filter ? '?filter=' + filter : ''}`
      )
      .pipe(map((users) => users.map((u) => new GraphUser(u))));
  }

  getGraphUser(id: string) {
    return this.httpClient
      .get<GraphUser>(`${this.apiBase}/graphusers/${id}`)
      .pipe(map((user) => new GraphUser(user)));
  }

  getGraphUsersById(ids: string[]) {
    return this.httpClient
      .post<GraphUser[]>(`${this.apiBase}/graphusersbyid`, ids)
      .pipe(map((users) => users.map((u) => new GraphUser(u))));
  }

  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(`${this.apiBase}/users`)
      .pipe(map((user) => user.map((u) => new GroupUser(u))));
  }

  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(`${this.apiBase}/groups`)
      .pipe(map((group) => group.map((g) => new GroupUser(g))));
  }

  getGroupsById(ids: string[], includeMembers = false) {
    return this.httpClient
      .get<GraphGroup[]>(
        `${this.apiBase}/groupsByid?ids=${ids.join(
          ';'
        )}&includeMembers=${includeMembers}`
      )
      .pipe(
        map((group) => group.map((g) => new GroupUser({ group: g as any })))
      );
  }

  getGraphGroups(filter?: string) {
    return this.httpClient
      .get<GraphGroup[]>(
        `${this.apiBase}/graphgroups${filter ? '?filter=' + filter : ''}`
      )
      .pipe(map((users) => users.map((u) => new GraphGroup(u))));
  }

  getTranslations(lang: string) {
    return this.httpClient.get<{ [key: string]: string }>(
      `${this.apiBase}/translations?lang=${lang}`
    );
  }

  getTilePages() {
    return this.httpClient
      .get<TilePage[]>(`${this.apiBase}/tilepages`)
      .pipe(map((pages) => pages.map((p) => new TilePage(p))));
  }

  updateTilePages(pages: TilePage[]) {
    return this.httpClient
      .put<TilePage[]>(`${this.apiBase}/tilepages`, pages)
      .pipe(map((ps) => ps.map((p) => new TilePage(p))));
  }

  uploadTileIcon(fileName: string, extension: string, base64: string) {
    return this.httpClient.post<string>(
      `${this.apiBase}/tilepages/tileIcon`,
      {
        relativeUrl: 'IshtarTileIcons',
        fileName: fileName + '.' + extension,
        base64,
      },
      { responseType: 'text' as 'json' }
    );
  }

  deleteTileIcon(relativeUrl: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/tilepages/tileIcon?relativeUrl=${relativeUrl}`
    );
  }

  getSkills() {
    return this.httpClient
      .get<Skill[]>(`${this.apiBase}/skills`)
      .pipe(map((skills) => skills.map((s) => new Skill(s))));
  }

  addSkills(skills: Skill[]) {
    return this.httpClient
      .post<Skill[]>(`${this.apiBase}/skills`, skills)
      .pipe(map((skills) => skills.map((s) => new Skill(s))));
  }

  updateSkills(skills: Skill[]) {
    return this.httpClient
      .patch<Skill[]>(`${this.apiBase}/skills`, skills)
      .pipe(map((skills) => skills.map((s) => new Skill(s))));
  }

  getUserSkills() {
    return this.httpClient
      .get<UserSkill[]>(`${this.apiBase}/userskills`)
      .pipe(map((skills) => skills.map((s) => new UserSkill(s))));
  }

  addUserSkills(skills: UserSkill[]) {
    return this.httpClient
      .post<UserSkill[]>(`${this.apiBase}/userskills`, skills)
      .pipe(map((skills) => skills.map((s) => new UserSkill(s))));
  }

  deleteUserSkills(skillIds: string[]) {
    return this.httpClient.delete<boolean>(`${this.apiBase}/userskills`, {
      body: skillIds,
    });
  }

  getAppConfig<T>(appName: string, constructor: new (obj: Partial<T>) => T) {
    return this.httpClient
      .get<T>(`${this.apiBase}/appconfig/${appName}`)
      .pipe(map((c) => new constructor(c)));
  }

  updateAppConfig<T>(
    appName: string,
    constructor: new (obj: Partial<T>) => T,
    config: T
  ) {
    return this.httpClient
      .post<T>(`${this.apiBase}/appconfig/${appName}`, config)
      .pipe(map((c) => new constructor(c)));
  }

  getWorkRegimeDays() {
    return this.httpClient
      .get<WorkRegimeDay[]>(`${this.apiBase}/workregimedays`)
      .pipe(
        map((workRegimeDays) => workRegimeDays.map((w) => new WorkRegimeDay(w)))
      );
  }

  addWorkRegimeDays(workRegimeDays: WorkRegimeDay[]) {
    return this.httpClient
      .post<WorkRegimeDay[]>(`${this.apiBase}/workregimedays`, workRegimeDays)
      .pipe(
        map((workRegimeDays) => workRegimeDays.map((w) => new WorkRegimeDay(w)))
      );
  }

  updateWorkRegimeDays(workRegimeDays: WorkRegimeDay[]) {
    return this.httpClient
      .patch<WorkRegimeDay[]>(`${this.apiBase}/workregimedays`, workRegimeDays)
      .pipe(
        map((workRegimeDays) => workRegimeDays.map((w) => new WorkRegimeDay(w)))
      );
  }

  getWorkRegimes() {
    return this.httpClient
      .get<WorkRegime[]>(`${this.apiBase}/workregimes`)
      .pipe(map((workRegimes) => workRegimes.map((w) => new WorkRegime(w))));
  }

  addWorkRegimes(workRegimes: WorkRegime[]) {
    return this.httpClient
      .post<WorkRegime[]>(`${this.apiBase}/workregimes`, workRegimes)
      .pipe(map((workRegimes) => workRegimes.map((w) => new WorkRegime(w))));
  }

  updateWorkRegimes(workRegimes: WorkRegime[]) {
    return this.httpClient
      .patch<WorkRegime[]>(`${this.apiBase}/workregimes`, workRegimes)
      .pipe(map((workRegimes) => workRegimes.map((w) => new WorkRegime(w))));
  }

  getWorkRegimeTypes() {
    return this.httpClient
      .get<WorkRegimeType[]>(`${this.apiBase}/workregimetypes`)
      .pipe(
        map((workRegimeTypes) =>
          workRegimeTypes.map((w) => new WorkRegimeType(w))
        )
      );
  }

  getDays() {
    return this.httpClient
      .get<Day[]>(`${this.apiBase}/days`)
      .pipe(map((days) => days.map((d) => new Day(d))));
  }

  getBlob(url: string) {
    return this.httpClient.get<Blob>(url, { responseType: 'blob' as 'json' });
  }

  getContactDetailParams() {
    return this.httpClient
      .get<ContactDetailParam[]>(`${this.apiBase}/contactDetailParams`)
      .pipe(map((params) => params.map((p) => new ContactDetailParam(p))));
  }
  getCustomSecurityAttributes() {
    return this.httpClient
      .get<CustomSecurityAttribute[]>(
        `${this.apiBase}/users/customSecurityAttributes`
      )
      .pipe(map((params) => params.map((p) => new CustomSecurityAttribute(p))));
  }
  runSync(appName: string) {
    return this.httpClient.get<any>(`${this.apiBase}/aadsync/${appName}/run`);
  }

  getSharepointSites(tenantId: string) {
    return this.httpClient.get<SharepointSite[]>(
      `${this.apiBase}/organizations/${tenantId}/sites`
    );
  }

  getInterestGroups() {
    return this.httpClient
      .get<InterestGroup[]>(`${this.apiBase}/interestgroups/getGroups`)
      .pipe(map((groups) => groups.map((g) => new InterestGroup(g))));
  }
  addInterestGroups(interestGroup: InterestGroup[]) {
    return this.httpClient
      .post<InterestGroup[]>(
        `${this.apiBase}/interestgroups/addGroup`,
        interestGroup
      )
      .pipe(map((gs) => gs.map((g) => new InterestGroup(g))));
  }
  updateInterestGroups(interestGroup: InterestGroup[]) {
    return this.httpClient
      .put<InterestGroup[]>(
        `${this.apiBase}/interestgroups/updateGroup`,
        interestGroup
      )
      .pipe(map((gs) => gs.map((g) => new InterestGroup(g))));
  }
  deleteInterestGroups(interestGroupIds: string[]) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/interestgroups/deleteGroup/${interestGroupIds}`
    );
  }
  uploadInterestGroupIcon(fileName: string, extension: string, base64: string) {
    return this.httpClient.post<string>(
      `${this.apiBase}/tilepages/tileIcon`,
      {
        relativeUrl: 'IshtarInterestGroupIcons',
        fileName: fileName + '.' + extension,
        base64,
      },
      { responseType: 'text' as 'json' }
    );
  }

  getLibraries(){
    return this.httpClient
      .get<Library[]>(`${this.apiBase}/libraries`)
      .pipe(
        map((libraries) => libraries.map((l) => new Library(l)))
    );
  }
  getFormTemplates(){
    return this.httpClient
    .get<FormTemplate[]>(`${this.apiBase}/formTemplates`)
    .pipe(map((formTemplates) => formTemplates.map((f) => new FormTemplate(f))));
  }
  getMetadataParameters(){
    return this.httpClient
    .get<MetadataParameter[]>(`${this.apiBase}/parameters`)
    .pipe(map((params) => params.map((p) => new MetadataParameter(p))));
  }
}
