import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { FrameManagerService } from 'src/app/core/services/frame-manager.service';
import { TileAction } from 'src/app/core/domain/enums/tile-action.enum';
import { Tile } from 'src/app/core/domain/models/tile.model';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'app-custom-tile',
  templateUrl: './custom-tile.component.html',
  styleUrls: ['./custom-tile.component.scss'],
  imports: [CommonModule],
})
export class CustomTileComponent implements OnInit {
  @Input() tile!: Tile;

  @Input() size = 80;
  @Input() margin = 20;

  @Input() inSidebar = false;

  @Input() intranet = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (!this.inSidebar) {
      this.size = (event.target as Window)?.innerWidth < 550 ? 50 : 80;
      this.margin = (event.target as Window)?.innerWidth < 550 ? 10 : 20;
    }
  }

  get fontSize() {
    return Math.max(this.size / 5, 10);
  }

  objectUrl?: SafeResourceUrl;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly tilePageFacade: TilePageFacade,
    private readonly sanitizer: DomSanitizer,
    private readonly frameManager: FrameManagerService
  ) {}

  ngOnInit(): void {
    if (!this.inSidebar) {
      this.size = window.innerWidth < 500 ? 50 : 80;
      this.margin = window.innerWidth < 500 ? 10 : 20;
    }
    this.objectUrl = this.transform(this.tile.iconUrl);
  }

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  open() {
    switch (this.tile.tileActionType) {
      case TileAction.Link: {
        let url = this.tile.tileAction;
        if (!url.startsWith('http')) url = `https://${url}`;
        window.open(url, '_blank');
        break;
      }
      case TileAction.TilePage:
        this.tilePageFacade.getTilePageByIdFunc$
          .pipe(first())
          .subscribe((fn) => {
            const page = fn(this.tile.tileAction);
            if (page) {
              let routeStart = this.route.snapshot.routeConfig?.path;
              if (!routeStart || !routeStart.startsWith('page')) {
                routeStart = 'page';
              }
              this.frameManager.backToIshtar365();
              this.router.navigate([routeStart + '/' + page.title]);
            }
          });
        break;
      default:
        console.error(this.tile, 'Incorrect tile action');
    }
  }

  imgErrored(event: ErrorEvent) {
    const img = event.target as HTMLImageElement;
    img.src = environment.cdnImageNotFound;
    // if (this.notFoundImage) img.src = this.notFoundImage as string;
  }

  isSingleWord(text: string): boolean {
    return !/\s/.test(text);
  }

  isLongerThanTwoLines(text: string): boolean {
    const lineHeight = 1.2;
    const fontSize = this.fontSize;
    const lines = 2;
    const width = this.size;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      return false;
    }
    context.font = `${fontSize}px Arial`;
    const textWidth = context.measureText(text).width;

    return textWidth > lineHeight * fontSize * lines * width;
  }
}
