import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/shared/store/app.reducer';
import { IshtarOrganization } from '../../domain/models/ishtar-organization.model';
import {
  getGroupUsers,
  getOrganizationAdmins,
  getOrganizationInfo,
  resetSlice,
  resetTempProperties,
  updateOrganizationPersonalization,
  updateTempLogo,
  updateTempNavColor,
  updateTempNavContrast,
} from './organization.actions';
import { organizationSelectors } from './organization.selectors';
import { GroupUser } from 'processdelight-angular-components';
import { BaseFacade } from '../base.facade';

@Injectable({
  providedIn: 'root',
})
export class OrganizationFacade extends BaseFacade {
  organizationInfo$ = this._store.pipe(
    select(organizationSelectors.getOrganizationInfo)
  );
  tempProperties$ = this._store.pipe(
    select(organizationSelectors.getTempProperties)
  );
  organizationAdmins$ = this._store.pipe(
    select(organizationSelectors.getOrganizationAdmins)
  );
  groupUsers$ = this._store.pipe(select(organizationSelectors.getGroupUsers));
  getUserIsOrganizationAdminFunc$ = this._store.pipe(
    select(organizationSelectors.getUserIsOrganizationAdminFunc)
  );
  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getOrganizationInfo(tenantId: string, callback?: () => void) {
    this._store.dispatch(getOrganizationInfo({ tenantId, callback }));
  }
  getOrganizationAdmins(callback?: () => void) {
    this._store.dispatch(getOrganizationAdmins({ callback }));
  }
  getGroupUsers(callback?: (groupUsers: GroupUser[]) => void) {
    this._store.dispatch(getGroupUsers({ callback }));
  }
  getOrganizationInfo$(tenantId: string) {
    return this.dispatchAction$(getOrganizationInfo, { tenantId });
  }
  getOrganizationAdmins$() {
    return this.dispatchAction$(getOrganizationAdmins);
  }
  getGroupUsers$() {
    return this.dispatchAction$(getGroupUsers);
  }

  updateOrganizationPersonalization$(
    logo: string,
    navColor: string,
    navContrast: string
  ) {
    return this.dispatchAction$(updateOrganizationPersonalization, {
      logo,
      navColor,
      navContrast,
    });
  }

  updateTempNavColor(color?: string) {
    this._store.dispatch(updateTempNavColor({ navColor: color }));
  }

  updateTempNavContrast(color?: string) {
    this._store.dispatch(updateTempNavContrast({ navContrast: color }));
  }

  updateTempLogo(logo?: string) {
    this._store.dispatch(updateTempLogo({ logo }));
  }

  resetTempProperties() {
    this._store.dispatch(resetTempProperties());
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
